import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeAgo = _resolveComponent("TimeAgo")!
  const _component_CollectionTable = _resolveComponent("CollectionTable")!

  return (_openBlock(), _createBlock(_component_CollectionTable, {
    collection: _ctx.pitchMoments,
    headers: _ctx.headers,
    onRowClicked: _ctx.goToPitchMoment
  }, {
    "header-name": _withCtx(({header}) => [
      _createElementVNode("th", {
        class: _normalizeClass(["whitespace-nowrap pt-4 pb-0 text-muted", [header.col]])
      }, _toDisplayString(_ctx.$t('headers.pitchMoment')), 3)
    ]),
    deadline: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createVNode(_component_TimeAgo, {
          date: row.deadlineAt
        }, null, 8, ["date"])
      ])
    ]),
    _: 1
  }, 8, ["collection", "headers", "onRowClicked"]))
}