
import PitchMoment, {PitchMoments} from '@/models/PitchMoment';
import {PropType, defineComponent} from 'vue';
import CollectionTable from '@/components/common/model-collection/CollectionTable.vue';
import TimeAgo from '@/components/common/TimeAgo.vue';

export default defineComponent({
    components: {
        CollectionTable,
        TimeAgo,
    },
    props: {
        pitchMoments: {
            type: Object as PropType<PitchMoments>,
            required: true,
        },
    },
    setup() {
        return {
            headers: [
                'name',
                'deadline',
            ],
        };
    },
    methods: {
        goToPitchMoment(pitchMoment: PitchMoment) {
            this.$router.push(pitchMoment.getLocation());
        },
    },
});
